<template>
  <div class="main">
    <div class="info-main-box">
      <div class="info-box-top">
        <div class="info-box-title">
          <div class="info-box-title-icon"></div>
          <div>串店顾客信息</div>
        </div>
        <div class="info-box-item">
          <div>串店顾客姓名</div>
          <div>{{info.msg.name}}</div>
        </div>
        <div class="info-box-item">
          <div>串店顾客手机</div>
          <div>{{info.msg.phone}}</div>
        </div>
      </div>
      <div class="info-box-top">
        <div class="info-box-title">
          <div class="info-box-title-icon"></div>
          <div>顾客原归属门店信息</div>
        </div>
        <div class="info-box-item">
          <div>门店店长</div>
          <div>{{oldShop.name}}</div>
        </div>
        <div class="info-box-item">
          <div>门店名称</div>
          <div>{{oldShop.depName}}</div>
        </div>
        <div class="info-box-item-address">
          <div class="address-name">门店地址</div>
          <div class="address-text">{{oldShop.depAddress}}</div>
        </div>
      </div>
      <div class="info-box-top">
        <div class="info-box-title">
          <div class="info-box-title-icon"></div>
          <div>顾客串访门店信息</div>
        </div>
        <div class="info-box-item">
          <div>门店店长</div>
          <div>{{newShop.name}}</div>
        </div>
        <div class="info-box-item">
          <div>门店名称</div>
          <div>{{newShop.depName}}</div>
        </div>
        <div class="info-box-item-address">
          <div class="address-name">门店地址</div>
          <div class="address-text">{{newShop.depAddress}}</div>
        </div>
      </div>
      <div class="info-box-top">
        <div class="info-box-title">
          <div class="info-box-title-icon"></div>
          <div>顾客互动</div>
        </div>
        <div class="info-box-item">
          <div>互动方式</div>
          <div>{{info.msg.interactive}}</div>
        </div>
        <div class="info-box-item">
          <div>分享人</div>
          <div>{{newShop.name}}</div>
        </div>
        <div class="info-box-item">
          <div>互动时间</div>
          <div>{{info.msg.createTime}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getInfo } from '@/api/shop'
import {Dialog, Toast} from 'vant';
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      noticeId:'',
      info: '', // 原始详情
      oldShop: {}, // 格式化后的信息
      newShop: {}
    }},
  created() {
    const query = this.$route.query
    if (!query.noticeId) {
      Dialog.alert({title: '提示', message: '参数错误',})
      return
    }
    this.noticeId = query.noticeId
    document.title = '串店预警'
    this.getInfo()
  },
  mounted:function(){
  },
  methods: {
    // 获取表头信息
    getInfo() {
      const postInfo = {}
      postInfo.noticeId = this.noticeId
      getInfo(postInfo).then(response => {
        this.info = response.data
        // 格式化数据 顾客原归属门店信息及顾客串访门店信息
        for( let i = 0; i<response.data.noticeUsers.length; i++){
          if(response.data.noticeUsers[i].isOriginalStore === 1){
            this.oldShop = response.data.noticeUsers[i]
          }
          if(response.data.noticeUsers[i].isOriginalStore === 0){
            this.newShop = response.data.noticeUsers[i]
          }
        }
      })
    }
  }
}
</script>

<style scoped>
  .main{
    background-color: #F8F8F8;
  }
  .info-main-box{
    margin: 20px 10px 10px 10px;
  }
  .info-box-top{
    margin: 15px 0 0 0;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 15px;
  }
  .info-box-title{
    display: flex;
    justify-content: flex-start;
    font-weight: bold;
  }
  .info-box-title-icon{
    width: 5px;
    height: 20px;
    background: #2586FF;
    color: #2586FF;
    margin-right: 10px;
  }
  .info-box-item{
    height: 50px;
    line-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    border-bottom: 1px dashed #E5E5E5 ;
  }
  .info-box-item-address{
    line-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    border-bottom: 1px dashed #E5E5E5 ;
  }
  .address-name{
    width: 20vw;
  }
  .address-text{
    line-height: 25px;
    max-width: 60vw;
  }
</style>
