import request from '@/utils/request'
const baseUrl = 'https://state.renruikeji.cn/api'
// const baseUrl = 'https://teststate.renruikeji.cn/api'
// 获取串店预警详情
export function getInfo(data) {
  return request({
    url: '/userservice/tags/msg/getMsgDetail',
    method: 'post',
    params: data,
    baseURL:baseUrl
  })
}
// 获取红包充值详情
export function getRedInfo(data) {
  return request({
    url: '/cp/redApproval/getRechargeInfo',
    method: 'post',
    params: data,
    baseURL:baseUrl
  })
}
// 红包充值通过
export function redPass(data) {
  return request({
    url: '/cp/redApproval/adopt',
    method: 'post',
    data,
    dataType: 'json',
    baseURL:baseUrl
  })
}
// 红包充值拒绝
export function redNo(data) {
  return request({
    url: '/cp/redApproval/reject',
    method: 'post',
    data,
    dataType: 'json',
    baseURL:baseUrl
  })
}
// 获取密钥
export function getCosToken(data) {
  return request({
    url: '/erp/backend/bucket/getUrl',
    method: 'post',
    params: data,
    baseURL: baseUrl
  })
}

